<template>
  <a-modal 
    :visible="visible"
    @cancel="closeModal"
    :footer="null"
    :class="{ 'visible-login-modal': showLoginModal,  'visible-signup-modal': showSignUpModal, 'visible-organization-modal': showOrganizationModal}"
   class="login-modal">
    <div class="modal-container">
      <div class="modal-container__left">
        <div v-show="showLoginModal" class="content-wrapper">
          <div class="content-wrapper__logo-box">
            <img
                class="logo"
                src="../../assets/images/Amblem2@2x.webp"
                alt="Glocal Logo"
            />
          </div>
          <h1 class="content-wrapper__heading">Login to Glocal</h1>
          <form class="login-form" @submit.prevent="submitLoginForm">
            <div class="form-fields">
              <input
                  type="email"
                  class="form-input"
                  data-cy="username-input"
                  required
                  placeholder="Email Address"
                  v-model.trim="loginEmail"
              />
              <a-input-password 
                v-model:value="loginPassword" 
                placeholder="Password" 
                class="form-input" 
              />
              <div class="error-field">
                <p v-if="error" class="error">{{ error }}</p>
                <p
                    v-if="verificationError"
                    @click="sendVerificationEmail"
                    class="error verification-link"
                >
                  Send verification link
                </p>
                <div v-if="onVerificationRequest" class="loader">
                  <the-loader/>
                </div>
              </div>
              <p class="email-successfull" v-if="emailLinkSuccess">
                Verification link sent to your email successfully.
              </p>
              <a-button
                  class="form-btn btn-primary form-btn--sign-in"
                  data-cy="loginButton"
                  htmlType="submit"
                  :loading="onLoginRequest"
              >
                Login
              </a-button>
              <div class="forgot-password">
                <span @click="forgotPassword">Forgot Password</span>
              </div>
              <div class="or">
                <p class="or-para">or</p>
              </div>
              <p class="sign-up-now">
                Don't have an account?
                <span @click="goToSignup()" class="link" data-cy="sign-up">Subscribe</span>
              </p>
            </div>
          </form>
        </div>
        <div data-cy="signUpModal" v-if="showSignUpModal" class="content-wrapper">
          <div class="content-wrapper__logo-box">
            <img
                class="logo"
                src="../../assets/images/Amblem2@2x.webp"
                alt="Glocal Logo"
            />
          </div>
          <h1 class="content-wrapper__heading">Get started with a 2 week free trial</h1>
          <div class="content-wrapper__toggle-buttons">
            <button @click="goToSignup()" 
              class="content-wrapper__toggle-buttons--btn" 
              :class="{'content-wrapper__toggle-buttons--active': showSignUpModal}" 
            >Individual</button>
            <button @click="goToOrganization()" 
              class="content-wrapper__toggle-buttons--btn"
              :class="{'content-wrapper__toggle-buttons--active': showOrganizationModal}" 
              >Organization</button>
          </div>
          <form data-cy="signUpForm" class="login-form" @submit.prevent="submitSignupForm">
            <div class="form-fields">
              <input
                  type="email"
                  class="form-input"
                  data-cy="signup-userEmail"
                  required
                  placeholder="Email Address"
                  v-model.trim="signupEmail"
              />
              <input
                  type="text"
                  class="form-input"
                  data-cy="signup-username"
                  required
                  placeholder="Full name / Pseudonym"
                  v-model="fullName"
              />
              <a-input-password 
                v-model:value="signupPassword" 
                required 
                placeholder="Password" 
                class="form-input"
               />
              <p v-if="signupError" data-cy="signupError" class="error">{{ signupError }}</p>
              <a-button
                  class="form-btn btn-primary form-btn--sign-in"
                  data-cy="subscribeButton"
                  htmlType="submit"
                  :loading="onSignupRequest"
              >
              Create Account
              </a-button>
              <p class="agreement-text">
                By creating an account, you agree to Glocal’s
                <router-link to="">
                  user agreement
                </router-link>
                and
                <router-link
                    target="_blank"
                    to="/the-privacy"
                    data-cy="privacyLink"
                >
                  privacy
                </router-link>
                <router-link
                    target="_blank"
                    to="/the-policy"
                    data-cy="policyLink"
                >
                  policy.
                </router-link>
              </p>
              <div class="or">
                <p class="or-para">or</p>
              </div>
              <p class="sign-up-now">
                Already have an account?
                <span @click="goToLogin()" class="link">Login</span>
              </p>
            </div>
          </form>
        </div>
        <div v-if="showOrganizationModal" class="content-wrapper">
          <div class="content-wrapper__logo-box">
            <img
                class="logo"
                src="../../assets/images/Amblem2@2x.webp"
                alt="Glocal Logo"
            />
          </div>
          <h1 class="content-wrapper__heading">Get started with a 2 week free trial</h1>
          <div class="content-wrapper__toggle-buttons">
            <button @click="goToSignup()" 
              class="content-wrapper__toggle-buttons--btn" 
              :class="{'content-wrapper__toggle-buttons--active': showSignUpModal}" 
            >Individual</button>
            <button @click="goToOrganization()" 
              class="content-wrapper__toggle-buttons--btn"
              :class="{'content-wrapper__toggle-buttons--active': showOrganizationModal}" 
              >Organization</button>
          </div>
          <form class="login-form" @submit.prevent="submitOrganizationForm">
            <div class="form-fields">
              <a-row :gutter="[20, 0]">
                <a-col :xs="24" :sm="24" :md="24" :lg="24">
                  <a-select
                    v-model:value="organization.sector"
                    show-search
                    placeholder="Select a Sector"
                    style="width: 100%; margin-bottom: 20px;"
                    :default-active-first-option="false"
                    :show-arrow="true"
                    :filter-option="false"
                    :options="filteredSectors"
                  ></a-select>
                </a-col>
              </a-row>
              <input
                  type="text"
                  class="form-input"
                  required
                  placeholder="Organization name"
                  v-model.trim="organization.name"
              />
              <input
                  type="email"
                  class="form-input"
                  required
                  placeholder="Email"
                  v-model="organization.email"
              />
              <a-input-password
                  class="form-input"
                  required
                  placeholder="Password"
                  v-model:value="organization.password"
              />
              
              <p v-if="OrganizationError" class="error">{{ OrganizationError }}</p>
              <a-button
                  class="form-btn btn-primary form-btn--sign-in"
                  data-cy="subscribeButton"
                  htmlType="submit"
                  :loading="onOrganizationRequest"
              >
              Create Account
              </a-button>
              <p class="agreement-text">
                By creating an account, you agree to Glocal’s
                <router-link to="">
                  user agreement
                </router-link>
                and
                <router-link
                    target="_blank"
                    to="/the-privacy"
                    data-cy="privacyLink"
                >
                  privacy
                </router-link>
                <router-link
                    target="_blank"
                    to="/the-policy"
                    data-cy="policyLink"
                >
                  policy.
                </router-link>
              </p>
              <div class="or">
                <p class="or-para">or</p>
              </div>
              <p class="sign-up-now">
                Already have an account?
                <span @click="goToLogin()" class="link">Login</span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service.js";
import TheLoader from "../BaseComponents/TheLoader.vue";
import StorageService from "@/services/storage.service";

export default {
  components: {
    TheLoader,
  },
  props: {
    visible: Boolean,
    type: String,
  },
  watch: {
    type(val) {
      if(val === 'login'){
        this.showLoginModal = true;
        this.showSignUpModal = false;
        this.showOrganizationModal = false
      } else if (val === 'signup') {
        this.showSignUpModal = true
        this.showLoginModal = false
        this.showOrganizationModal = false
      } else if (val === 'organization') {
        this.showOrganizationModal = true
        this.showLoginModal = false
        this.showSignUpModal = false
      }
    },
  },
  computed: {
    ...mapGetters('discover',['invitedArticleId']),
    ...mapGetters('folders',['isFolderRequested','requestedFolderId']),
    topicURL(){
      return this.$store.getters["discover/getTopicURL"];
    },
    
  },
  emits: ["showForgotPasswordModal", "show-modal", "showSubscriptionModal", "closeAccessModal"],
  data() {
    return {
      loginEmail: "",
      loginPassword: "",
      error: "",
      showForgotPasswodModal: false,
      onLoginRequest: false,
      showAccActModal: false,
      verificationError: false,
      emailLinkSuccess: false,
      onVerificationRequest: false,
      showLoginModal: false,
      showSignUpModal: false,
      showOrganizationModal: false,
      onSignupRequest: false,
      onOrganizationRequest: false,
      fullName: "",
      signupEmail: "",
      signupPassword: "",
      signupError: "",
      OrganizationError: false,
      filteredSectors: [],
      organization: {
        name: "",
        email: "",
        password: "",
        sector: "profit",
      },
       sectors: [
        { value: 'profit', label: 'Profit' },
        { value: 'non_profit', label: 'Non Profit' },
        { value: 'academia', label: 'Academia' },
        { value: 'government', label: 'Government' },
      ],
    };
  },
  created() {
     this.filteredSectors = this.sectors;
  },
  methods: {
    closeModal(){
      this.$emit("closeAccessModal", false);
    },
    async submitLoginForm() {
      try {
        this.onLoginRequest = true;
        this.signupError = "";
        this.error = "";
        this.verificationError = false;
        this.emailLinkSuccess = false;
        const payload = {
          email: this.loginEmail,
          password: this.loginPassword,
        };
        ApiService.removeHeader();
        const response = await this.$store.dispatch("auth/login", payload);
        if (response.status === 200) {
          if (this.topicURL) {
            this.$store.commit("discover/SET_TOPIC_URL", `/discover${this.topicURL}`);
            // window.location.href = this.topicURL;
            this.$router.push({ path: `${this.topicURL}`});
          }
          else if (this.invitedArticleId) { 
            // window.location.href = `/discover/article/${this.invitedArticleId}`;
            this.$router.push({ path: `/discover/article/${this.invitedArticleId}`});
          }
          else if (this.isFolderRequested) { 
            this.$router.push({ path: `/folders/institute/${this.requestedFolderId}`})
          }
          else {
            this.$store.commit("discover/SET_TOPIC_URL", '/discover');
            // window.location.href = '/discover';
            this.$router.push({ path: '/discover'});
          }
        }
      } catch (err) {
        if (err.response.status === 400) {
          Object.keys(err.response.data).forEach((key, i, arr) => {
            err.response.data[key].forEach((val) => {
              if (val === "Email is not verified.") {
                this.error += val + "\n";
                this.verificationError = true;
              } else {
                this.verificationError = false;
                this.error += val + "\n";
              }
            });
          });
        } else if (err.response.status === 403) {
          this.showAccActModal = true;
          this.$emit("show-modal", this.showAccActModal);
        }
      } finally {
        this.onLoginRequest = false;
      }
    },
    forgotPassword() {
      this.$emit("showForgotPasswordModal");
    },
    async sendVerificationEmail() {
      this.onVerificationRequest = true;

      const requestObj = {
        email: this.loginEmail,
      };

      try {
        const response = await ApiService.post(
            "user/send-verification-email/",
            {email: requestObj.email}
        );

        if (response.status === 200) {
          this.emailLinkSuccess = true;
        }
      } catch (err) {
        console.log(err);
      }
      this.onVerificationRequest = false;
    },
    goToSignup() {
      this.emitter.emit('changeFormType', 'signup')
      this.showLoginModal = false, 
      this.showOrganizationModal = false
      this.showSignUpModal = true;
    },
    goToLogin() {
      this.emitter.emit('changeFormType', 'login')
      this.showSignUpModal = false,
      this.showOrganizationModal = false
      this.showLoginModal = true;
    },
    goToOrganization() {
      this.emitter.emit('changeFormType', 'organization')
      this.showSignUpModal = false;
      this.showLoginModal = false;
      this.showOrganizationModal = true;
    },
    async submitSignupForm() {
      try {
        this.onSignupRequest = true;
        this.error = "";
        this.signupError = "";

        const payload = {
          email: this.signupEmail,
          password: this.signupPassword,
          confirm_password: this.signupPassword,
          profile: {
            full_name: this.fullName,
            phone_number: "+923101234567",
          },
        }
        const response = await this.$store.dispatch("auth/signUp", payload);

        if (response.status === 201) {
          await this.$store.dispatch("auth/login", payload);
          await this.$router.replace(`/discover`)
        }
      } catch (error) {
        if (error.response.data.email) {
          this.signupError = error.response.data.email[0];
        } else {
          this.signupError = error.response.data.password[0];
        }
      }
      this.onSignupRequest = false;
    },
    async submitOrganizationForm() {
      try {
        this.onOrganizationRequest = true;
         const payload = {
          email: this.organization.email,
          password: this.organization.password,
        }
          const response = await this.$store.dispatch("auth/organizationSignUp", this.organization);
        if (response.status === 201) {
          await this.$store.dispatch("auth/login", payload);
          await this.$router.replace(`/discover`);
          }
        } catch (error) {
          this.OrganizationError = error.response.data.email[0];
      } finally {
           this.onOrganizationRequest = false;
        }
      },
  },
};
</script>

<style lang="scss">
.ant-modal-mask {
  backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
}

.visible-signup-modal,
.visible-organization-modal {
  width: 50rem !important;
  top: 8rem !important;
}

.visible-login-modal {
  transition: 1s;
  width: 50rem !important;

  .ant-modal-content {
    .ant-modal-body {
      .modal-container {
        &__left {
          width: 100% !important;
        }
      }
    }
  }
}

.login-modal {
  width: 95rem;
  top: 8rem;

  @include respond(tab-port) {
    width: 50rem !important;
    top: 10rem;
  }

  .ant-modal-content {
    border-radius: 0;

    .ant-modal-close {
      margin: 3.5rem 3rem 0 0;
      .ant-modal-close-x {
        padding: 0.5rem;
        width: fit-content;
        height: fit-content;
        line-height: normal;
        .anticon {
          color: $color-black;
        }
      }
    }

    .ant-modal-body {
      padding: 0 !important;

      .modal-container {
        display: flex;
        padding: 4rem 3.7rem;

        &__left {
          width: 100%;
          padding: 0;
          @include respond(tab-port) {
            width: 100%;
          }


          .content-wrapper {
            &__logo-box {
              margin-bottom: 2.4rem;
              .logo {
                width: 3.6rem;
                display: block;
                margin: auto;
              }
            }
            &__heading {
              text-align: center;
              font-size: 2rem;
              font-family: $font-primary-bold;
              margin-bottom: 2rem;
              color: $color-black;
              line-height: 2.7rem;
            }
            &__toggle-buttons {
              display: flex;
              margin-bottom: 2rem;
              border: 1px solid $color-dark-grey-5;
              border-radius: 1rem;
              &--btn {
                width: 50%;
                border: none;
                background-color: transparent;
                height: 4rem;
                border-radius: 1rem !important;
                color: $color-black;
                font-size: 1.4rem;
                font-family: $font-primary-medium;
                outline: none;
                cursor: pointer;
              }
              &--active {
                background-color: $color-primary;
                color: $color-white;
              }
            }
            &__para {
              text-align: center;
              padding: 0 4rem;
              margin-bottom: 2.8rem;
            }
          }

          .form-fields {
            display: flex;
            flex-direction: column;
            padding: 0;

            .form-input {
              height: 4.6rem;
              border: 1px solid #24242433;
              margin-bottom: 2rem;
              padding: 0 1.4rem;
              font-size: 1.6rem;
              font-family: $font-primary;
              box-shadow: none;
              outline: none;
              color: $color-black;
              border-radius: 7px !important;
              width: 100%;
              &::placeholder {
                opacity: 1;
                color: #7b7b7b;
              }
              .ant-input {
                font-size: 1.6rem;
                font-family: $font-primary;
                color: $color-black;
                background-color: transparent;
                &::placeholder {
                  opacity: 1;
                  color: #7b7b7b;
                }
              }
              .ant-input-suffix {
                .anticon {
                  font-size: 2rem;
                }
              }
            }
            .ant-select {
              height: 4.6rem;
              border: 1px solid #24242433;
              margin-bottom: 2rem;
              border-radius: 7px !important;
              .ant-select-selector {
                height: 100% !important;
                border: none;
                background-color: transparent;
                box-shadow: none;
                font-size: 1.6rem;
                font-family: $font-primary;
                padding: 0 1.4rem;
                .ant-select-selection-search {
                  .ant-select-selection-search-input {
                    height: 100%;
                  }
                }
                .ant-select-selection-placeholder {
                  color: #7b7b7b;
                }
              }
            }

            .form-btn {
              font-family: $font-primary-medium;
              font-size: 1.6rem;
              height: 4.6rem;
              margin-top: 1.5rem;
              outline: none;
              border: none;
              cursor: pointer;
              border-radius: 7px !important;

              &--sign-in-google {
                color: $color-black;
                background-color: transparent;
                // used important for emergeny fix need to debug
                border: 1px solid #19191924 !important;
                margin-bottom: 1.4rem;
              }

              &--sign-in-linkedin {
                color: $color-white;
                background-color: #0077b7;
                border: 1px solid #19191924;
                margin-bottom: 3.1rem;
                cursor: pointer;
              }
            }

            .forgot-password {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin: 1.6rem 0;

              span {
                text-align: center;
                font-family: $font-secondary;
                font-size: 1.6rem;
                color: $color-black;
                text-decoration: underline;
                cursor: pointer;
                width: fit-content;

                &:hover {
                  color: $color-primary;
                  text-decoration: none;
                }
              }
            }

            .agreement-text {
              opacity: 1;
              font-size: 1.6rem;
              font-family: $font-primary;
              color: $color-black;
              padding: 0 4rem;
              line-height: 2rem;
              margin: 1.6rem 0;
              text-align: center;
              @include respond(tab-port) {
                padding: 0;
              }
              a {
                color: $color-primary;
                cursor: pointer;
                font-family: $font-primary-medium;
              }
            }

            .or {
              position: relative;
              margin-bottom: 1.6rem;

              .or-para {
                text-align: center;
                margin: 0rem;
                color: $color-black;
                font-family: $font-primary;
                font-size: 1.6rem;

                &::before {
                  content: "";
                  display: block;
                  width: 30%;
                  height: 1px;
                  background: #CECECE;
                  left: 15%;
                  top: 50%;
                  transform: translateY(50%);
                  position: absolute;
                }

                &::after {
                  content: "";
                  display: block;
                  width: 30%;
                  height: 1px;
                  background: #CECECE;
                  right: 15%;
                  top: 50%;
                  transform: translateY(50%);
                  position: absolute;
                }
              }
            }

            .sign-up-now {
              margin: 0 !important;
              font-size: 1.6rem;
              font-family: $font-primary;
              text-align: center;
              color: $color-black;
              .link {
                color: $color-primary;
                text-decoration: none;
                font-family: $font-primary-medium;
                cursor: pointer;
              }
            }

            .error-field {
              display: flex;
              align-items: center;
              margin-bottom: 1.5rem;

              .error {
                font-size: 1.5rem;
                width: fit-content;
                margin: 0;
                font-family: $font-primary;
                line-height: normal;
              }

              .verification-link {
                color: $color-primary;
                margin-left: 0.5rem;
                text-decoration: underline;
                font-family: $font-primary-bold;
                cursor: pointer;
              }

              .loader {
                margin-left: 1rem;
                line-height: normal;

                .ant-spin {
                  line-height: normal;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .anticon {
                    width: 1rem;
                    height: 1rem;
                    font-size: 0;

                    .anticon-spin {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }

            .email-successfull {
              color: #469f13;
              font-size: 1.2rem;
              font-family: $font-primary;
            }
          }
        }

        &__right {
          width: 50%;
          padding: 6.5rem 1rem 0;
          background-image: url("../../assets/images/Signup-modal-Image.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;

          @include respond(tab-port) {
            display: none;
          }

          &--title {
            text-align: center;
            font-size: 2.2rem;
            font-family: $font-primary-bold;
            line-height: 3rem;
            margin-bottom: 5rem;
          }

          .ant-carousel {
            .slick-slider {
              .slick-list {
                .slick-track {
                  .slick-slide {
                    .carousel {
                      text-align: center;
                      padding: 1rem 0;

                      &__title {
                        font-size: 1.8rem;
                        font-family: $font-primary-bold;
                        line-height: 3rem;
                      }

                      &__description {
                        font-size: 1.6rem;
                        font-family: $font-primary;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }

              .slick-dots {
                margin-top: 5rem;
                position: inherit;

                li {
                  button {
                    background-color: $color-primary !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
